import React from "react";
import Block from "../components/Block";

const Projects = () => {
  return (
    <div className="pt-4">
      <Block className="col-span-12 p-4">
        <h1 className="text-4xl">Projects</h1>
        <p>This section of the website is under construction. Stay tuned for updates!</p>
      </Block>
    </div>
  );
};

export default Projects;
