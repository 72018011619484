import { motion } from "framer-motion";
import { Link } from "react-router-dom";

type LinkTextProps = {
  site: string;
  children: React.ReactNode;
  hoverScale?: number
}

const LinkText = ({ site, children, hoverScale=1.1 }: LinkTextProps) => {
  return (
    <motion.div
      whileHover={{ scale: hoverScale }}
      whileTap={{ scale: 1.0 }}
    >
      <Link to={`/${site}`} className="">
        {children}
      </Link>
    </motion.div>
  );
};

export default LinkText;
