import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Button } from "rsuite";

const MobileSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="md:hidden relative z-20">
      {/* Button bleibt an seiner ursprünglichen Position */}
      <motion.button
        onClick={toggleSidebar}
        className="text-gray-700 p-2 focus:outline-none z-50 relative"
      >
        <div className="space-y-1">
          <motion.span
            initial={{ backgroundColor: "#FFF7ED", rotate: 0, y: 0 }}
            animate={{
              backgroundColor: isOpen ? "#262626" : "#FFF7ED",
              rotate: isOpen ? 135 : 0,
              y: isOpen ? 6 : 0,
              scale: isOpen ? 1.5 : 1,
            }}
            transition={{
                duration: 0.3,
                type: "spring",
                stiffness: 50,
                damping: 10,}}
            className="block w-6 h-0.5 bg-primary rounded-full"
          ></motion.span>
          <motion.span
            initial={{ backgroundColor: "#FFF7ED", scale: 1 }}
            animate={{
              backgroundColor: isOpen ? "#262626" : "#FFF7ED",
              scale: isOpen ? 0 : 1,
            }}
            transition={{
                duration: 0.3,
                type: "spring",
                stiffness: 50,
                damping: 10,}}
            className="block w-6 h-0.5 bg-primary rounded-full"
          ></motion.span>
          <motion.span
            initial={{ backgroundColor: "#FFF7ED", rotate: 0 }}
            animate={{
              backgroundColor: isOpen ? "#262626" : "#FFF7ED",
              rotate: isOpen ? -135 : 0,
              y: isOpen ? -6 : 0,
              scale: isOpen ? 1.5 : 1,
            }}
            transition={{
                duration: 0.3,
                type: "spring",
                stiffness: 50,
                damping: 10,}}
            className="block w-6 h-0.5 bg-primary rounded-full"
          ></motion.span>
        </div>
      </motion.button>

      {/* Sidebar */}
      <motion.div
        className="fixed top-0 left-0 h-screen w-screen bg-secondaryBlock shadow-lg z-40"
        initial={{ x: "200%" }}
        animate={{ x: isOpen ? "0%" : "200%" }}
        transition={{ duration: 0.3 }}
      >
        <div className="p-4 mt-20">
          <nav>
            <ul className="space-y-8">
              {[
                { titel: "Home", domain: "" },
                { titel: "Timeline", domain: "timeline" },
                { titel: "Projects", domain: "projects" },
                { titel: "Contact", domain: "contact" },
              ].map((obj, index) => (
                <li key={index}>
                  <Link to={`/${obj.domain}`} className="text-secondary ">
                    <Button onClick={toggleSidebar} className="text-4xl font-mono font-normal">{obj.titel}</Button>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </motion.div>

      
    </div>
  );
};

export default MobileSidebar;
