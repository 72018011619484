import React from "react";
import { motion } from "framer-motion";
import Block from "../components/Block";
import { defaultSpringTransition } from "../utils";

type BigTimeLineProps = {
  setBlur: (value: boolean) => void;
  setSelectedEvent: React.Dispatch<React.SetStateAction<number | null>>;
  events: EventType[];
  animateEvents: boolean;
};

type EventType = {
  date: string;
  sec_date: string;
  topic: string;
  sec_topic: string;
  shortText: React.ReactNode;
  fullText: React.ReactNode;
  category: string;
  showInSelected: boolean;
};

const BigTimeline: React.FC<BigTimeLineProps> = ({
  setBlur,
  setSelectedEvent,
  events,
  animateEvents,
}) => {
  return (
    <div className="">
      <motion.div
        className="overflow-y-auto pt-2"
        transition={{ duration: 0.3 }}
      >
        {events.map((event, index) => (
          <motion.div
            key={index + 1}
            className="flex gap-x-3 w-full"
            initial={animateEvents ? { opacity: 0 } : false}
            animate={animateEvents ? { opacity: 1 } : false}
            transition={{
              delay: index * 0.2,
              duration: animateEvents ? 1 : 0,
              ease: "easeInOut",
            }}
          >
            <div className="w-20 lg:w-28 text-end font-mono text-xs text-neutral-400 hidden md:block">
              <p className="mt-2  ">
                {event.date}
                {event.sec_date === "" ? "" : "-"}
              </p>
              <p>{event.sec_date}</p>
            </div>
            {/* Icon */}
            <div
              className={`relative ${
                index === events.length - 1
                  ? ""
                  : "after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-neutral-300"
              }`}
            >
              <div className="relative z-10 size-7 flex justify-center items-center">
                <div className="size-3 rounded-full bg-neutral-400"></div>
              </div>
            </div>
            {/* End Icon */}
            {/* Right Content */}
            <div className="w-full h-full md:w-2/3 flex flex-shrink">
              <motion.div
                initial={animateEvents ? { x: 100 } : false}
                animate={animateEvents ? { x: 0 } : false}
                transition={defaultSpringTransition(index * 0.2 + 0.2)}
                onClick={() => {
                  setSelectedEvent(index);
                  setBlur(true);
                }}
                className="cursor-pointer pr-4"
              >
                <div className=" text-start my-1 font-mono text-xs text-neutral-400 md:hidden">
                  <p className="">
                    {event.date}
                    {event.sec_date === "" ? "" : " - "}
                    {event.sec_date}
                  </p>
                </div>
                <Block
                  variant="secondary"
                  className="w-auto h-auto mb-8 p-4 hover:shadow-[0_0_5px_white]" //shadow-[0_0_100px_white]
                  layoutId={`event-${index}`} // Pass layoutId directly
                >
                  <div className="grow">
                    <h3 className="flex font-mono text-sm font-semibold text-secondary">
                      {event.topic}
                    </h3>
                    <div className="text-sm font-serif font-extralight text-secondarySec">
                      <p>{event.sec_topic}</p>
                      <div
                        className={`${event.shortText === "" ? "" : "mt-1"}`}
                      >
                        {event.shortText}
                      </div>
                    </div>
                  </div>
                </Block>
              </motion.div>
            </div>

            {/* End Right Content */}
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default BigTimeline;
