// ContactForm.tsx
import React, { useRef, useState, FormEvent } from "react";
import emailjs from "@emailjs/browser";
import { motion } from "framer-motion";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm: React.FC = () => {
  const form = useRef<HTMLFormElement>(null);
  const [feedbackMessage, setFeedbackMessage] = useState<string>("");
  const [capVal, setCapVal] = useState<string | null>(null);

  const sendEmail = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (form.current) {
      emailjs
        .sendForm(
          "service_aq8p85p", // EmailJS Service ID
          "template_g6chabn", // EmailJS Template ID
          form.current,
          "bo8GZVJEYCOXBe_mI" // EmailJS Public Key
        )
        .then(
          (result) => {
            console.log(result.text);
            setFeedbackMessage("Ihre Nachricht wurde erfolgreich gesendet!");
            form.current!.reset(); // Reset the form after erfolgreichem Absenden
          },
          (error) => {
            console.log(error.text);
            setFeedbackMessage(
              "Es gab ein Problem beim Senden Ihrer Nachricht."
            );
          }
        );
    }
  };

  return (
    <form
      ref={form}
      onSubmit={sendEmail}
      className="w-full mx-auto p-6 grid grid-cols-2 gap-4"
    >
      <div className="col-span-2 md:col-span-1">
        <input
          type="text"
          name="from_name"
          placeholder="Name *"
          autoComplete="given-name"
          className="w-full px-3 py-2 bg-orange-50 text-neutral-700 placeholder-neutral-400 rounded-xl focus:outline-none focus:shadow-[0_0_10px_black]"
          required
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <input
          type="text"
          name="from_surname"
          placeholder="Surname *"
          autoComplete="family-name"
          className="w-full px-3 py-2 bg-orange-50 text-neutral-700 placeholder-neutral-400 rounded-xl focus:outline-none focus:shadow-[0_0_10px_black]"
          required
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <input
          type="email"
          name="from_email"
          placeholder="Email *"
          autoComplete="email"
          className="w-full px-3 py-2 bg-orange-50 text-neutral-700 placeholder-neutral-400 rounded-xl focus:outline-none focus:shadow-[0_0_10px_black]"
          required
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <input
          type="tel"
          name="phone"
          placeholder="Phone"
          autoComplete="tel"
          className="w-full px-3 py-2 bg-orange-50 text-neutral-700 placeholder-neutral-400 rounded-xl focus:outline-none focus:shadow-[0_0_10px_black]"
        />
      </div>
      <div className="col-span-2">
        <input
          type="text"
          name="topic"
          placeholder="Subject *"
          className="w-full px-3 py-2 bg-orange-50 text-neutral-700 placeholder-neutral-400 rounded-xl focus:outline-none focus:shadow-[0_0_10px_black]"
          required
        />
      </div>
      <div className="col-span-2">
        <textarea
          name="message"
          placeholder="Message *"
          rows={6}
          className="w-full px-3 py-2 bg-orange-50 text-neutral-700 placeholder-neutral-400 rounded-xl focus:outline-none focus:shadow-[0_0_10px_black]"
          required
        ></textarea>
      </div>
      <div className="col-span-2 md:col-span-1">
        <ReCAPTCHA
          sitekey="6LfGSU8qAAAAANypDeNp1frRqdovOTT-CU4J_zVf"
          onChange={(val) => setCapVal(val)}
          theme="dark"
        />
      </div>
      <div className="col-span-2 md:col-span-1 flex justify-center md:justify-end">
        <div className="max-w-52 grow">
          <motion.input
            type="submit"
            value="Send"
            disabled={!capVal}
            initial={{
              backgroundColor: "#404040",
              color: "#FFF7ED",
              scale: 1.0,
            }}
            whileTap={!capVal ? undefined : { scale: 0.95 }}
            whileHover={
              !capVal
                ? undefined
                : { backgroundColor: "#FFF7ED", color: "#262626" }
            }
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className=" px-4 py-2 w-full rounded-xl cursor-pointer shadow-[0_0_10px_white]"
          />
        </div>
      </div>

      {feedbackMessage && (
        <div className=" text-orange-50 flex justify-end items-center">
          {feedbackMessage}
        </div>
      )}
    </form>
  );
};

export default ContactForm;
