import React, { useState, useEffect, useContext } from "react";
import Block from "../components/Block";
import BigTimeline from "../components/BigTimeline";
import { motion, AnimatePresence } from "framer-motion";
import { AnimationContext } from "../App";
import { defaultSpringTransition } from "../utils";

type EventType = {
  date: string;
  sec_date: string;
  topic: string;
  sec_topic: string;
  shortText: React.ReactNode;
  fullText: React.ReactNode;
  category: string;
  showInSelected: boolean;
};

const Timeline = () => {
  const { homePageVisited, setHomePageVisited, blur, setBlur } =
    useContext(AnimationContext);
  const [selectedCategory, setSelectedCategory] = useState<string>("Selected");
  const [animateEvents, setAnimateEvents] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false); // State for dropdown visibility

  useEffect(() => {
    if (!homePageVisited) {
      setHomePageVisited(true);
    }
  }, [homePageVisited, setHomePageVisited]);

  const events: EventType[] = [
    {
      date: "Sep 2024",
      sec_date: "Dec 2024",
      topic: "Exchange Semester",
      sec_topic: "University of California, San Diego, USA",
      shortText: (
        <div>
          <p>
            <span className="font-semibold">Program:</span> UPS Extended Studies
          </p>
          <p>
            <span className="font-semibold">Focus:</span> Natural Language
            Processing and Computer Vision
          </p>
          <p>
            <span className="font-semibold">Experience:</span> Gained
            international perspective in advanced AI fields.
          </p>
        </div>
      ),
      fullText: (
        <div>
          <p>
            During my{" "}
            <span className="font-semibold">
              Exchange Semester at the University of California, San Diego
              (UCSD)
            </span>
            , I participated in the UPS Extended Studies Program, which allowed
            me to dive deep into the fields of Natural Language Processing (NLP)
            and Computer Vision.
          </p>
          <p>
            This semester provided an opportunity to study{" "}
            <span className="font-semibold">cutting-edge topics</span> in AI
            under the guidance of renowned professors and alongside a diverse
            group of international students. I explored advanced methods in NLP,
            including neural language models and sentiment analysis, as well as
            state-of-the-art techniques in Computer Vision, such as image
            recognition and object detection.
          </p>
          <p>
            The experience of studying at a world-class institution in the USA
            not only broadened my technical knowledge but also enriched my
            cultural perspective.
          </p>
        </div>
      ),
      category: "Education",
      showInSelected: true,
    },
    {
      date: "Oct 2022",
      sec_date: "Present",
      topic: "Bachelor of Science, Computer Science",
      sec_topic: "Technical University of Munich, Germany",
      shortText: (
        <div>
          <p>
            <span className="font-semibold">Major:</span> Computer Science,
            Minor in Mathematics
          </p>
          <p>
            <span className="font-semibold">Current GPA:</span> 1.3 (top 3%)
          </p>
        </div>
      ),
      fullText: (
        <div>
          <p>
            I am currently pursuing my{" "}
            <span className="font-semibold">
              Bachelor of Science in Computer Science
            </span>{" "}
            at the Technical University of Munich, with a minor in Mathematics.
            My current GPA stands at 1.3, placing me in the top 3% of my class.
          </p>
          <p>
            Throughout my studies, I have developed a strong foundation in both
            theoretical and practical aspects of computer science. Here are some
            selected courses that highlight my academic achievements:
          </p>
          <ul className="list-disc pl-5">
            <li>
              <span className="font-semibold">
                Fundamentals of Programming:
              </span>{" "}
              Mastered core programming concepts using Java, earning a grade of
              1.0 (top 2%).
            </li>
            <li>
              <span className="font-semibold">
                Introduction to Theory of Computation:
              </span>{" "}
              Studied automata theory, formal languages, Turing machines, and
              computability, achieving a grade of 1.0 (top 1%).
            </li>
            <li>
              <span className="font-semibold">
                Practical Course Computer Architecture:
              </span>{" "}
              Completed a project on RSA key generation using C and Assembly,
              applying concepts of computer architecture and cryptography.
              Grade: 1.3 (top 7%).
            </li>
            <li>
              <span className="font-semibold">
                Fundamentals of Algorithms and Data Structures:
              </span>{" "}
              Gained proficiency in designing and optimizing algorithms and data
              structures for various software applications, achieving a grade of
              1.0 (top 5%).
            </li>
          </ul>
          <p>
            My studies have equipped me with a deep understanding of
            computational theory and hands-on experience in programming,
            allowing me to tackle complex problems with a strong analytical
            approach.
          </p>
        </div>
      ),
      category: "Education",
      showInSelected: true,
    },
    {
      date: "Sep 2024",
      sec_date: "Present",
      topic: "Member of the Board, Faculties Football League",
      sec_topic: "Website Development",
      shortText: (
        <div>
          <p>
            <span className="font-semibold">Role:</span> Supporting the development of a new website and league management.
          </p>
          <p>
            <span className="font-semibold">Skills:</span> Web development, project coordination.
          </p>
        </div>
      ),
      fullText: (
        <div>
          <p>
            As a <span className="font-semibold">Member of the Board at the Faculties Football League</span>, I am actively involved in <span className="font-semibold">developing a new website</span> for the league. This role includes working closely with the team to ensure the smooth integration of features for player registration, team management, and communication.
          </p>
          <p>
            My contributions focus on <span className="font-semibold">web development</span> and enhancing the league's digital presence, providing a modern and user-friendly experience for participants.
          </p>
        </div>
      ),
      category: "Extracurriculars",
      showInSelected: false,
    },
    {
      date: "Mar 2024",
      sec_date: "Jul 2024",
      topic: "Robotics Trainee, Agile Robots",
      sec_topic: "Munich, Germany",
      shortText: (
        <div>
          <p>
            <span className="font-semibold">
              Developed teleoperation software
            </span>{" "}
            and integrated AI-based algorithms for humanoid robotic systems,
            primarily using Python, C++, and ROS.
          </p>
          <p>
            <span className="font-semibold">
              Integrated GELLO, haptic gloves, and trackers
            </span>{" "}
            for precise control of robotic systems.
          </p>
          <p>
            <span className="font-semibold">Presented demos to partners</span>{" "}
            and clients, showcasing system advancements.
          </p>
        </div>
      ),
      fullText: (
        <div>
          <p>
            During my time as a{" "}
            <span className="font-semibold">
              Robotics Trainee at Agile Robots
            </span>
            , I developed and implemented advanced teleoperation software for
            humanoid robotic systems. This involved integrating AI-based
            algorithms and optimizing control systems using Python, C++, and
            ROS.
          </p>
          <p>
            A significant part of my role involved{" "}
            <span className="font-semibold">enhancing the GELLO system</span>,
            where I adjusted, tested, and fine-tuned control software for
            robotic arms using SpaceMouse and GELLO. This led to smoother and
            more intuitive user interactions.
          </p>
          <p>
            I also integrated a{" "}
            <span className="font-semibold">
              haptic glove (Dexmo force feedback)
            </span>{" "}
            to enable precise finger tracking, providing a natural and intuitive
            control mechanism for robotic hands. The integration of external
            trackers (HTC Vive) further improved spatial tracking, allowing for
            seamless control in three-dimensional space.
          </p>
          <p>
            Additionally, I actively participated in workshops, where I{" "}
            <span className="font-semibold">presented live demos</span> to
            external partners and clients, showcasing our latest technological
            advancements and highlighting real-world applications of our
            systems.
          </p>
        </div>
      ),
      category: "Work",
      showInSelected: true,
    },
    {
      date: "Sept 2014",
      sec_date: "Jun 2022",
      topic: "Hertzhaimer-Gymnasium Trostberg",
      sec_topic: "Secondary School, Germany",
      shortText: (
        <div>
          <p>
            <span className="font-semibold">GPA:</span> 1.9 (German system: 1.0 best, 4.0 pass)
          </p>
          <p>
            <span className="font-semibold">Award:</span> Top CS Graduate, German Informatics Society
          </p>
          <p>
            <span className="font-semibold">Activities:</span> Student Council (SMV), organized school events.
          </p>
        </div>
      ),
      fullText: (
        <div>
          <p>
            I completed my secondary education at the <span className="font-semibold">Hertzhaimer-Gymnasium in Trostberg</span>, graduating with a GPA of 1.9. I was honored with the <span className="font-semibold">Top Computer Science Graduate Award</span> from the German Informatics Society, recognizing my academic excellence in the field.
          </p>
          <p>
            During my time at the gymnasium, I was actively involved in the <span className="font-semibold">Student Council (Schülermitverantwortung, SMV)</span>, where I contributed to the planning and organization of various school events. 
          </p>
        </div>
      ),
      category: "Education",
      showInSelected: true,
    },    
    {
      date: "Aug 2021",
      sec_date: "Juni 2022",
      topic: "Member of the Student Council (SMV)",
      sec_topic: "Hertzhaimer-Gymnasium Trostberg",
      shortText: <p></p>,
      fullText: <div>Organizing of school related events.</div>,
      category: "Extracurriculars",
      showInSelected: false,
    },
    {
      date: "Jul 2022",
      sec_date: "Sep 2022",
      topic: "Student Assistant, Dyneon GmbH",
      sec_topic: "Burgkirchen an der Alz, Germany",
      shortText: (
        <div>
          <p>
            <span className="font-semibold">Role:</span> Data processing and visualization in a production environment.
          </p>
          <p>
            <span className="font-semibold">Skills:</span> Data analysis, visualization tools.
          </p>
        </div>
      ),
      fullText: (
        <div>
          <p>
            As a <span className="font-semibold">Student Assistant at Dyneon GmbH</span>, I worked on <span className="font-semibold">processing and visualizing production data</span> to support optimization and decision-making processes within the company. 
          </p>
        </div>
      ),
      category: "Work",
      showInSelected: false,
    },
    {
      date: "Aug 2020",
      sec_date: "Present",
      topic: "Construction Field Assistant, Kirchleitner Zimmerei",
      sec_topic: "Tyrlaching, Germany",
      shortText: (
        <div>
          <p>
            <span className="font-semibold">Role:</span> Supporting construction projects, office tasks, and enhancing online presence.
          </p>
          <p>
            <span className="font-semibold">Skills:</span> Project coordination, administrative work, digital marketing.
          </p>
        </div>
      ),
      fullText: (
        <div>
          <p>
            In my role as a <span className="font-semibold">Construction Field Assistant at Kirchleitner Zimmerei</span>, I assist with various <span className="font-semibold">construction projects</span>, providing on-site support and helping with <span className="font-semibold">administrative tasks</span> in the office. Additionally, I contribute to the company's <span className="font-semibold">online presence</span> by managing the website.
          </p>
        </div>
      ),
      category: "Work",
      showInSelected: false,
    },
    {
      date: "Jul 2019",
      sec_date: "",
      topic: "Internship, Heidenhain",
      sec_topic: "Traunreut, Germany",
      shortText: (
        <div>
          <p>
            <span className="font-semibold">Focus:</span> Mechatronics, soldering circuit boards, and coding.
          </p>
          <p>
            <span className="font-semibold">Skills:</span> Hands-on experience with hardware and software integration.
          </p>
        </div>
      ),
      fullText: (
        <div>
          <p>
            During my <span className="font-semibold">internship at Heidenhain</span>, I gained valuable insights into the field of <span className="font-semibold">mechatronics</span>. My tasks included <span className="font-semibold">soldering circuit boards</span> and working on coding projects, allowing me to understand the integration of hardware and software in a professional environment.
          </p>
        </div>
      ),
      category: "Work",
      showInSelected: false,
    },
    {
      date: "Mar 2019",
      sec_date: "",
      topic: "Polish Students Exchange Program",
      sec_topic: "Wrocław, Poland",
      shortText: (
        <div>
          <p>
            <span className="font-semibold">Focus:</span> Intercultural competence and communication skills.
          </p>
          <p>
            <span className="font-semibold">Experience:</span> Gained insights into Polish culture and lifestyle.
          </p>
        </div>
      ),
      fullText: (
        <div>
          <p>
            As part of a <span className="font-semibold">Polish students exchange program in Wrocław</span>, I had the opportunity to immerse myself in a new cultural environment. This experience significantly enhanced my <span className="font-semibold">intercultural competence</span> and helped me develop <span className="font-semibold">stronger communication skills</span> in diverse settings.
          </p>
        </div>
      ),
      category: "Extracurriculars",
      showInSelected: false,
    },
  ];
  const categories = [
    "Selected",
    ...Array.from(new Set(events.map((event) => event.category))),
  ];
  const filteredEvents =
    selectedCategory === "Selected"
      ? [
          ...events.filter((event) => event.showInSelected),
          {
            date: "2004",
            sec_date: "",
            topic: "Born in Bavaria, Germany",
            sec_topic: "",
            shortText: <div></div>,
            fullText: <div></div>,
            category: "",
            showInSelected: true,
          },
        ]
      : [
          ...events.filter((event) => event.category === selectedCategory),
          {
            date: "2004",
            sec_date: "",
            topic: "Born in Bavaria, Germany",
            sec_topic: "",
            shortText: <div></div>,
            fullText: <div></div>,
            category: "",
            showInSelected: true,
          },
        ];

  const [selectedEvent, setSelectedEvent] = useState<number | null>(null);
  return (
    <div className="w-full h-full pt-4">
      <motion.div
        className="w-full h-full"
        animate={{ filter: blur ? "blur(5px)" : "blur(0px)" }}
      >
        <Block variant="primary" className="py-4 pl-2">
          <div className="w-full flex justify-end mb-4 lg:mb-0">
            <div className="relative inline-block text-left w-56 mr-4">
              <div className="">
                <motion.button
                  type="button"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  className="inline-flex justify-between w-full rounded-xl px-4 py-2 bg-secondaryBlock text-sm font-medium text-secondary shadow-[0_0_10px_black] hover:shadow-[0_0_5px_white]"
                  id="menu-button"
                  aria-expanded={dropdownOpen}
                  aria-haspopup="true"
                  initial={{ opacity: 0, x: 300 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={defaultSpringTransition(0.2)}
                >
                  {selectedCategory}
                  {/* Arrow Icon */}
                  <svg
                    className={`ml-2 h-5 w-5 transform transition-transform ${
                      dropdownOpen ? "rotate-180" : "rotate-0"
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 12.94l3.71-5.71a.75.75 0 111.06 1.06l-4.25 6.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 01.02-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </motion.button>
              </div>
              <AnimatePresence>
                {dropdownOpen && (
                  <motion.div
                    className="origin-top-right z-10 absolute left-0 mt-2 w-56 rounded-xl shadow-[0_0_10px_black] bg-secondaryBlock "
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 50, scale: 0.1 }}
                    transition={{ duration: 0.1, ease: "easeOut" }}
                  >
                    <div className="p-1" role="none">
                      {categories.map((category) => (
                        <button
                          key={category}
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedCategory(category);
                            setDropdownOpen(false);
                            setAnimateEvents(false);
                          }}
                          className="flex px-4 py-2 text-sm w-full justify-start text-secondarySec hover:shadow-[0_0_5px_black] rounded-xl "
                          role="menuitem"
                        >
                          {category}
                        </button>
                      ))}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>

          <BigTimeline
            setBlur={setBlur}
            setSelectedEvent={setSelectedEvent}
            events={filteredEvents}
            animateEvents={animateEvents}
          />
        </Block>
      </motion.div>
      {selectedEvent !== null && (
        <Modal
          event={filteredEvents[selectedEvent]}
          onClose={() => {
            setSelectedEvent(null);
            setBlur(false);
          }}
          index={selectedEvent}
        />
      )}
    </div>
  );
};

type ModalProps = {
  event: EventType;
  onClose: () => void;
  index: number;
};

const Modal: React.FC<ModalProps> = ({ event, onClose, index }) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    document.body.style.overflow = "hidden"; // Prevent background scrolling
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.style.overflow = "auto";
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 z-50 flex items-center justify-center "
        onClick={onClose} // Close modal when clicking outside the content
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {/* Overlay with semi-transparent background */}
        <div className="absolute inset-0 bg-black bg-opacity-50" />

        {/* Modal Content */}
        <div className="h-2/3 flex items-center flex-col">
          <motion.div
            className="relative w-11/12 max-w-xl shrink p-6 ml-6 mr-6 rounded-lg bg-secondaryBlock  overflow-y-auto"
            layoutId={`event-${index}`} // Pass layoutId directly
            onClick={(e: React.MouseEvent<HTMLDivElement>) =>
              e.stopPropagation()
            } // Prevent click inside modal from closing it
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {/* Close Button */}
            <button
              className="absolute top-2 right-4 text-secondary hover:text-secondarySec"
              onClick={onClose}
            >
              &times;
            </button>
            {/* Event Content */}
            <motion.div className=" shrink">
              <h2 className="font-mono text-xl font-semibold text-secondary">
                {event.topic}
              </h2>
              <p className="text-lg font-serif font-extralight text-secondarySec">
                {event.sec_topic}
              </p>
              <div className="mt-4 text-base font-serif font-extralight text-secondarySec">
                {event.fullText}
              </div>
            </motion.div>
          </motion.div>
          <div className="grow"></div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Timeline;
