import React, { useContext } from "react";
import { motion } from "framer-motion";
import Block from "./Block";
import LinkText from "./LinkText";
import { AnimationContext } from "../App";
import MobileSidebar from "./MobileSidebar"
import { useLocation } from "react-router-dom";

type HeaderProps = {
};

const Header: React.FC<HeaderProps> = () =>  {
  const { homePageVisited, blur, onPhone } = useContext(AnimationContext);
  const animationsShouldPlay = !homePageVisited;

  const location = useLocation();
  const home = location.pathname === "/" && !onPhone;

  return (
    <motion.div initial={{}} animate={blur ? { filter: "blur(5px)" }: {}} className="sticky max-w-screen top-0 z-40 bg-neutral-900 p-4">
      <motion.div
      initial={
        animationsShouldPlay ? { scale: 0.3, opacity: 0, x: 0, y: 100 } : false
      }
      animate={{ scale: 1, opacity: 1, x: 0, y: 0 }}
      transition={{
        delay: animationsShouldPlay ? home ? 1.5 : 0.2 : 0,
        duration: animationsShouldPlay ? 0.5 : 0,
        type: "spring",
        stiffness: 50,
        damping: 10,
      }}
    >
      <Block className="col-span-12 p-6 max-w-6xl mx-auto">
        <div className="flex justify-between items-center">
          <LinkText site="" hoverScale={1.05}>
            <span className="text-xl font-thin italic">CHRISTIAN </span>
            <span className="text-xl font-normal not-italic">REMMELBERGER</span>
          </LinkText>

          <div className="text-xs ml-4 flex items-center space-x-8 font-light">
            <div className="hidden md:block"><LinkText site="projects">PROJECTS</LinkText></div>
            <div className="hidden md:block"><LinkText site="timeline">TIMELINE</LinkText></div>
            <div className="hidden md:block"><LinkText site="contact">CONTACT</LinkText></div>
            
            
            <MobileSidebar />
          </div>
        </div>
      </Block>
    </motion.div>
    </motion.div>
    
  );
}

export default Header;
