// ArrowIcon.tsx
import React from 'react';

const ArrowIcon: React.FC<{ className?: string }> = ({
  className = 'w-8 h-8',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7 17l10-10m0 0h-6m6 0v6"
    />
  </svg>
);

export default ArrowIcon;
