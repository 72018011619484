// utils.ts
import { Transition } from "framer-motion";

// Typisiere die Funktion und gib die Transition-Eigenschaften zurück
export const defaultSpringTransition = (delay: number = 0): Transition => ({
  delay: delay,
  duration: 0.5,
  type: "spring",
  stiffness: 50,
  damping: 10,
});


